<template>
        <!-- START CUSTOM TABS -->
        <div class="row">
          <div class="col-md-12">
            <!-- Custom Tabs -->
            <div class="row">
              <div class="col-md-12">
                <div class="box box-info" id="inbox-list">
                  <div class="box-header with-border" v-on:click="toggleSegmentCollapsedState('9')">
                    <i class="fa  fa-inbox"></i>
                    <h3 class="box-title head-title-padding">Customer Portal List <i class="fa fa-question-circle text-info help-icon"></i></h3>
                    <span aria-hidden="true" class="fa fa-angle-right pull-right expanded" v-bind:class = "segment9?'':'chevron-expand-indicator_expanded'"></span>
                  </div>
                  <!-- /.box-header -->
                  <!-- form start -->
                  
                  <div class="box-body table-responsive" v-bind:class = "segment9?'in collapse':''">
                    <div class="notdatatable form-inline mbottom-15">
                        <div class="table-controls table-columns">
                          <div class="btn-group"><button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                        </div>
                        <div class="table-controls table-pagesize table-columns">
                          <label>
                              Show 
                              <select class="form-control input-xs">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                              </select>
                              entries 
                          </label>
                        </div>
                        
                        <div class="table-controls table-filter">Filter Portal List <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                    </div>
                    <table class="table table-striped table-hover">
                      <tbody>
                        <tr id="row2" @click="showMessageDetails('others','row2')" v-bind:class = "active_el == 'row2' ?'row-active':''">
                        
                          <td>
                            Customer Portal Website 1
                          </td>
                          
                        </tr>

                        <tr id="row3" v-on:click="showMessageDetails('others','row3')" v-bind:class = " active_el == 'row3' ? 'row-active' : 'hell'">
                          <td>
                            Customer Portal Website 2
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div id="Main_MyDashboard_Recent_TableInfo">
                        <div class="notdatatable table-info">Showing 1 to 2 of 2 entries</div>
                        <div class="dataTables_paginate notdatatable table-pagination text-end">
                            <ul class="pagination">
                              <li><a href="">Previous</a></li>
                              <li class="active"><a href="">1</a></li> 
                              <li><a href="">Next</a></li>
                            </ul>
                        </div>
                      </div>
                    <!-- Data loader start
                    <div class="data-loader">
                      <i class="fa fa-spinner fa-spin fa-fw"></i>
                    </div>  -->
                    <!-- Data loader end-->
                  </div>
                </div>
              </div>
              <div class="col-md-4">
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
</template>

<script>
var previousrowVal;
export default {
  name: 'Customer Portal List TV',
  data() {
    return {
      showDetailstv : true,
      value: true,
      active_el : '',
      colEmail : false,
      segment9:false
    };
  },
  methods: {
    showMessageDetails(type,row) {
      if(previousrowVal == row){
          this.showDetailstv = !this.showDetailstv;
          if(!this.showDetailstv){
            this.active_el = '';
          }else{
            this.active_el = row;  
          }
         
      }else{
         this.showDetailstv = this.showDetailstv;
            this.active_el = row;     
      }
      previousrowVal = row;
      this.emitter.emit("show-message-details", {displayType:type, showDetailstv : this.showDetailstv});
      
    },
     toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
  }
}
</script>