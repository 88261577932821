<template>
 <div class="custom-icon">
                                    <section>
                                        <div class="row fontawesome-icon-list">
                                            <div class="col-md-3 col-sm-4">
                                              <i class="fa icon-c icon-ai-chatbot"></i>
                                                New Account
                                            </div>
                                            <div class="col-md-3 col-sm-4">
                                                <span class="fa-stack new-account-icon">
                                                    <i class="fa fa-users"></i>
                                                    <i class="fa fa-plus icon-plus"></i> 
                                                </span>

                                                New Account
                                            </div>
                                            <div class="col-md-3 col-sm-4">
                                                <span class="fa-stack new-account-icon">
                                                    <i class="fa fa-envelope-o"></i>
                                                    <i class="fa fa-clock-o icon-plus"></i> 
                                                </span>
                                                My Pending Messages
                                            </div>
                                             <div class="col-md-3 col-sm-4">
                                                <span class="fa-stack new-account-icon">
                                                    <i class="fa fa-envelope-o"></i>
                                                    <i class="fa fa-check icon-plus"></i> 
                                                </span>
                                               My Messages to Approve
                                            </div>
                                            <div class="col-md-3 col-sm-4">
                                                <span class="fa-stack new-account-icon">
                                                    <i class="fa fa-envelope-o"></i>
                                                    <i class="fa fa-gear icon-plus"></i> 
                                                </span>
                                                Manage Messages
                                            </div>
                                            <div class="col-md-3 col-sm-4">
                                                <span class="fa-stack new-account-icon">
                                                    <i class="fa fa-user"></i>
                                                    <i class="fa  fa-commenting icon-plus"></i> 
                                                </span>
                                                Logged in Chat Agents
                                            </div>
                                            <div class="col-md-3 col-sm-4">
                                               	<span class="fa-stack new-account-icon">
                                                <i class="fa fa-file-text-o"></i>
                                                <i class="fa  fa-gear icon-plus"></i> 
                                                </span>
                                                Manage Open Quotes
                                            </div>
                                            <div class="col-md-3 col-sm-4">
                                               <span class="fa-stack my-form-icon">
                                                    <i class="fa fa-newspaper-o"></i>
                                                    <i class="fa fa-user icon-myform"></i> 
                                                </span>
                                                My Forms
                                            </div>
                                            <div class="col-md-3 col-sm-4">
                                               <span class="fa-stack new-account-icon">
                                                    <i class="fa fa-envelope"></i>
                                                    <i class="fa fa-bullhorn icon-plus"></i> 
                                                </span>
                                               Campaign
                                            </div>

                                            <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-agent-notification-2"></i>
                                                Agent Notification
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-external-notification-1"></i>
                                               External Agent Notification
                                             </div>

                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-find-answer-update"></i>
                                               Find Answer Update
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-secure-response"></i>
                                                Secure Response
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-secure-email"></i>
                                                Private Email
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-customer-ticket"></i>
                                                Customer Ticket
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-agent-email-3"></i>
                                                Agent Email
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-agent-response"></i>
                                                Agent Response
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-secure-email"></i>
                                                Secure Email
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-public-note"></i>
                                                Public Note
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-private-note"></i>
                                                Private Note
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-resolve-note"></i>
                                                Resolve Note
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-unsent-response"></i>
                                                Unsent Response
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-sen-leave-open"></i>
                                                Send - Leave open
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-send-secure"></i>
                                                Send - Send Secure
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-expect-customer-reply"></i>
                                                Send - Expect Customer Reply
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-add-reference-number"></i>
                                               Send - Add Refernce Number
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-forward-to-agent"></i>
                                                Forward - Forward To Agent
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-unassign-question"></i>
                                                Forward - Unassign Question
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-forward-to-segment"></i>
                                                Forward - Forward To Segment
                                             </div>
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-forward-to-external-agent"></i>
                                                Forward - Forward to External Agent
                                             </div>
                                             
                                             <div class="col-md-3 col-sm-4">
                                                <i class="fa icon-c icon-custom-forms"></i>
                                                Custom Forms
                                             </div>
                                        </div>  
                                    </section>

                                    <section id="customicon">
                                        <h4 class="page-header">Interaction Icon</h4>

                                        <div class="row fontawesome-icon-list">
                                             <div class="col-md-3 col-sm-4">
                                                <img src="/img/agent.png" width="30">
                                                Agent
                                            </div>
                                            <div class="col-md-3 col-sm-4">
                                                <img src="/img/customer.png" width="30">
                                                Customer
                                            </div>
                                            
                                            <div class="col-md-3 col-sm-4">
                                                <img src="/img/system.png" width="30">
                                                System
                                            </div>
                                        </div>
                                    </section>
                                </div>
</template>