<template> 
    <div class="row">
                <div class="col-md-12">
                  <div class="nav-tabs-custom">
                     <ul class="nav nav-tabs">
                        <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" data-bs-target="#tab_messageportaldetail_1" role="tab" aria-controls="formbody" aria-selected="true"><i class="fa fa-gear"></i><span data-bs-toggle="tooltip" data-bs-placement="bottom"> Content Settings</span></a></li>
                        <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_msgportalproperty_2" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-cubes"></i>  <span>Page Components</span></a> </li>
                        <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_msgportalproperty_3" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa  fa-desktop"></i> <span>Theme Colors </span></a> </li>
                        <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_msgportalproperty_4" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-css3"></i> <span>Override CSS </span></a> </li> 
                     </ul>
                     <div class="tab-content">
                        <div class="tab-pane active" id="tab_messageportaldetail_1">
                          
                           <div class="row">
                           <p class="col-md-12">Enter the complete HTML for the header that will appear above the portal. This usually includes your company logo and website navigation links. You can use this to make the portal appear more integrated with your company website.</p>
                              <div class="form-group">
                                 <label class="col-md-12 control-label text-left">Homepage HTML</label>
                                 <div class="col-md-9">
                                 <textarea class="form-control h-300p"></textarea>
                                 </div>
                              </div>

                              <div class="form-group">
                                 <label class="col-md-12 control-label text-left">Navigation Menus</label>
                                 <div class="col-md-9">
                                    <div class="row">
                                       <div class="col-md-3"><input type="text" placeholder="Menu Name" class="form-control"></div>
                                       <div class="col-md-6"><input type="text" placeholder="Menu URL" class="form-control"></div>
                                       <div class="col-md-3"><button class="btn btn-primary">Add Menu</button></div>
                                    </div>
                                    <ul dnd-list="list" id="configNavigationMenus" class="mt-2 pl-0"></ul>
                                 </div>
                              </div>

                              <p class="col-md-12">Enter the complete HTML for the footer that will appear below the portal. This usually includes details that you include within the footer of your company website. You can use this to make the portal appear more integrated with your company website.</p>
                              
                              <div class="form-group">
                                 <label class="col-md-12 control-label text-left">Footer HTML</label>
                                 <div class="col-md-9"><textarea class="form-control h-300p" spellcheck="false"></textarea></div>
                              </div>
                              <div class="col-md-12">
                               <button class="btn btn-primary">Save</button>
                              </div>
                           </div>
                        </div>
                        <div class="tab-pane" id="tab_msgportalproperty_2">
                           <div class="row">
                            <p class="col-md-12"> Select the features that you want to appear within your message portal.
                            </p>
                           </div>
                           <div class="row form-horizontal">
                             
                              <div class="form-group col-md-12">
                                 <label class="col-md-3 control-label text-left"> Display Navigation Menus</label>
                                 <div class="col-md-9">
                                    <Toggle />
                                 </div>
                              </div>
                              <div class="form-group col-md-12">
                                 <label class="col-md-3 control-label text-left"> Use Custom Topic List</label>
                                 <div class="col-md-9">
                                 <Toggle />
                                 </div>
                              </div>
                           </div>
                        </div>
                     
                        <div class="tab-pane" id="tab_msgportalproperty_3">
                           <div class="row">
                           
                              <p>
                              Select the color theme for your portal. You can override colors by adding custom CSS to the Override Code Panel.
                              </p>
                              <div class="col-md-2">
                              <div class="row">
                                 <div class="theme-pallate">
                                 
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-1">
                                       <input type="radio" checked class="mright-15" name="theme" value="theme1" v-model="theme" v-on:click="selecttheme('theme1')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-2">
                                       <input type="radio" class="mright-15" name="theme" value="theme2" v-model="theme"  v-on:click="selecttheme('theme2')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                       
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-3">
                                       <input type="radio" class="mright-15" name="theme" value="theme3" v-model="theme"  v-on:click="selecttheme('theme3')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                       
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-4">
                                       <input type="radio" class="mright-15" name="theme" value="theme4" v-model="theme"  v-on:click="selecttheme('theme4')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-5">
                                       <input type="radio" class="mright-15" name="theme" value="theme5" v-model="theme"  v-on:click="selecttheme('theme5')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-6">
                                       <input type="radio" class="mright-15" name="theme" value="theme6" v-model="theme"  v-on:click="selecttheme('theme6')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-7">
                                       <input type="radio" class="mright-15" name="theme" value="theme7" v-model="theme"  v-on:click="selecttheme('theme7')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-8">
                                       <input type="radio" class="mright-15" name="theme" value="theme8" v-model="theme"  v-on:click="selecttheme('theme8')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-9">
                                       <input type="radio" class="mright-15" name="theme" value="theme9" v-model="theme"  v-on:click="selecttheme('theme9')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-10">
                                       <input type="radio" class="mright-15" name="theme" value="theme10" v-model="theme"  v-on:click="selecttheme('theme10')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-11">
                                       <input type="radio" class="mright-15" name="theme" value="theme11" v-model="theme"  v-on:click="selecttheme('theme11')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-12">
                                       <input type="radio" class="mright-15" name="theme" value="theme12" v-model="theme"  v-on:click="selecttheme('theme12')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-13">
                                       <input type="radio" class="mright-15" name="theme" value="theme13" v-model="theme"  v-on:click="selecttheme('theme13')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-14">
                                       <input type="radio" class="mright-15" name="theme" value="theme14" v-model="theme"  v-on:click="selecttheme('theme14')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-15">
                                       <input type="radio" class="mright-15" name="theme" value="theme15" v-model="theme"  v-on:click="selecttheme('theme15')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 color-palette-box msgportal-theme-16">
                                       <input type="radio" class="mright-15" name="theme" value="theme16" v-model="theme"  v-on:click="selecttheme('theme16')">
                                       <div class="color-palette-set">
                                          <div class="color-palette primary-color"></div>
                                          <div class="color-palette secondary-color"></div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              </div>

                              <div class="col-md-10">
                                 <div class="preview-box">
                                 <a :href="getImgUrl3(theme)" target="_blank">
                                    <img :src="getImgUrl3(theme)">
                                 </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="tab-pane" id="tab_msgportalproperty_4">
                           <div class="row form-verticle">
                              <p class="col-md-12 text-red">*Do not use this feature unless you are experienced with CSS and HTML.</p>
                              <p class="col-md-12">
                              You can override any of the code in your portal by using the panels below. Your changes will override any elements you select, including any theme settings.
                              </p>
                              <div class="form-group">
                                 <label class="col-md-12 control-label text-left">Head Elements <span v-tooltip="{text: 'You can add your own code to the HEAD section of the portals page. Be sure to surround your changes with the proper tags, such as <style>, <title>, etc.',theme: {placement: 'bottom', }}"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                                 <div class="col-md-9">
                                 <textarea type="text" class="form-control h-300p"></textarea>
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Component Templates <span v-tooltip="{text: 'You can override the Vue.js code that comprises the customer portal. Click here to view the contents of the underlying source code for the customer portal. Your code entered in this section will update the Vue.js Components.',theme: {placement: 'bottom', }}"><i class="fa fa-question-circle text-info help-icon"></i></span> </label>
                                 <div class="col-md-9">
                                 <textarea type="text" class="form-control h-300p"></textarea>
                                 </div>
                              </div>
                                 <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Components Javascript <span v-tooltip="{text: 'You can insert your own JavaScript into the customer portal using the section below. Either enclose your JS within script tags or reference a CDN version of the JS.',theme: {placement: 'bottom', }}"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                                 <div class="col-md-9">
                                    <textarea type="text" class="form-control h-300p"></textarea>
                                 </div>
                              </div>
                              <div class="col-md-12">
                              <button class="btn btn-primary">Save</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
   </div>

</template>

<script>
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import CustomerPortalList from '../components/CustomerPortalList.vue';
import DashbordConfig from '../components/DashbordConfig.vue';
import Toggle from '@vueform/toggle'
import Multiselect from '@vueform/multiselect'
import { ColorPicker } from "vue-accessible-color-picker";
  export default {
  name: 'Customer Portal Tabs',
   components: {
      DetailsandattachmentsTabContent,
      InteractionPropertyTab,
      Multiselect,
      CustomerPortalList,
      ColorPicker,
      DashbordConfig,
      Toggle
  },
  mounted(){
          
      },
   data() {
    return {
       color: 'black',
       color1: 'black',
       colpicker1 :false,
       colpicker2 : false ,
       color3: 'black',
       color4: 'black',
       colpicker3 :false,
       colpicker4 : false ,
       showDetails :false,
       showAlert:false,
       createNew:false,
       theme:'theme1'
    }
  },

  methods: {
    updateColor (eventData) {
      this.color = eventData.cssColor
    },
    updateColor1 (eventData) {
      this.color1 = eventData.cssColor
    },
    updateColor3 (eventData) {
      this.color3 = eventData.cssColor
    },
    updateColor4 (eventData) {
      this.color4 = eventData.cssColor
    },
    getImgUrl3(theme) {
       return require('../assets/theme/msgportal/'+theme+'.png')
    }
  },
  mounted() { 
    this.emitter.on("show-message-details", Data => {
      this.showDetails = Data.showDetails;
      this.createNew = false;
    });
  }
}

</script>