<template>
  <div class="row">
    <div class="message-box-header">
        <div class="col-md-12">
            <div class="subject-heading">
                <b>Subject : </b> 	Feedback provided
            </div>
        </div> 
        <div class="col-md-12">
            <div class="user-container">
                <span class="user">
                    <img src="/img/agent.png">
                    <b>Agent</b>
                </span>
                <span>John Doe</span>
            </div>  
            <div class="message-info">
                <b>From : </b>john.doe@example.com<br>
                <b>To : </b>alek.packard@1to1service.com<br>
                <b>CC : </b>justin.time@1to1service.com<br>
            </div>	
            <div class="int-det-box pull-right"> 
                <div><b>Interaction ID : </b>4645646</div>
                <div><b> Segment / Topic : </b>Segment1 / All Topic</div>
                <div><b> Resolved By : </b>Alek Packard</div>
                <div><b> Approved By : </b>&nbsp;</div><div><b> Assigned To : </b>&nbsp;</div>		
                <div><b> Interaction Status : </b><small class="bg-green-active label">Resolved</small></div>									
                <div><b> Interaction Type : </b><small class="label label-with-icon bg-blue"><i class="fa fa-envelope"></i>Customer Email</small><span class="email-download" v-tooltip="{text: 'Download Customer Email',theme: {placement: 'left', }}" ><i class="fa fa-download"></i></span></div>
            </div>
        </div>
        <div class="clearfix"></div>
            <div class="col-md-12 mtop-15"><b><i class="fa fa-paperclip attachment-icon"></i></b><a href="" class="attachments"><i class="fa fa-file-image-o"></i> <span class="filename">Image1.jpg (123KB)</span></a> <a href=""  class="attachments"><i class="fa fa-file-image-o"></i> <span class="filename">Image2.jpg (30kb)</span></a> <a href=""  class="attachments"><i class="fa fa-file-pdf-o"></i><span class="filename"> File1.pdf (40KB)</span></a> </div>
        </div>
    </div>
</template>
<script>
  export default {
  name: 'DetailsandattachmentsTabContentComponents',
   components: {

  },
  data: function(){
     
  }

}

</script>