<template>

   <!--<CustomerPortalListTV></CustomerPortalListTV>
   <div class="row">
      <div class="col-md-12 form-group">
         <button class="btn btn-primary" type="button" v-on:click="createNewtv = true; showDetailstv = false">
            New Portal
         </button>
         <button class="btn btn-danger btn-delete" type="button" v-on:click="showAlert = true">
            Delete Portal...
         </button>
      </div>
   </div>
   <div class="row" v-if="showAlert">
      <div class="col-md-12">
         <div class="box box-danger">
            <div class="box-header with-border">
               <i class="fa fa-warning"></i>
               <h3 class="box-title">Alerts</h3>
            </div>
            <div class="box-body">
               <div class="row">
                  <div class="col-md-12">
                     <p class="text-red">Deletion is immediate and irrevocable upon
                        confirmation.</p>
                  </div>
                  <div class="col-md-12">
                     <button type="button" class="btn btn-danger btn-sm">Confirm
                        Deletion</button>
                     <button type="button" class="btn btn-link" v-on:click="showAlert = false">Cancel</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="row" v-if="createNewtv && !showDetailstv">
     <div class="col-md-12">
         <div class="box">
            <div class="box-header with-border">
               <i class="fa fa-globe"></i>
               <h3 class="box-title text-right">New Customer Portal</h3>
            </div>
            <div class="box-body">
              
               <div class="row">
                <div class="col-md-12">
                  <div class="nav-tabs-custom">
                     <ul class="nav nav-tabs">
                        <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" data-bs-target="#tvtab_property_5" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-ellipsis-h"></i> <span>Details </span></a> </li>
                        <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tvtab_detail_1" role="tab" aria-controls="formbody" aria-selected="true"><i class="fa fa-gear"></i><span data-bs-toggle="tooltip" data-bs-placement="bottom">Header/Footer</span></a></li>
                        <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tvtab_property_2" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-cubes"></i>  <span>Page Component</span></a> </li>
                        <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tvtab_property_3" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa  fa-desktop"></i> <span>Theme Colors </span></a> </li>
                        <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tvtab_property_4" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-css3"></i> <span>Override Code  </span></a> </li> 
                     </ul>
                     <div class="tab-content">
                        <div class="tab-pane" id="tvtab_detail_1">
                           <div class="form-horizontal">
                              <p>
Enter the complete HTML for the header that will appear above
the portal. This usually includes your company logo and website
navigation links. You can use this to make the portal appear
more integrations with your company website.
                              </p>
                              <div class="form-group">
                                 <label class="col-md-12 control-label text-left">Header HTML</label>
                                 <div class="col-md-9">
                                 <textarea class="form-control h-300p"></textarea>
                                 </div>
                              </div>
                              <p>
              Enter the complete HTML for the header that will appear below
the portal. This usually includes details that you include within
the footer of your company website. You can use this to make the portal appear
more integrations with your company website.
                              </p>
                              <div class="form-group">
                                 <label class="col-md-12 control-label text-left">Footer HTML</label>
                                 <div class="col-md-9">
                                 <textarea class="form-control h-300p"></textarea>
                                 </div>
                              </div>
                              <button class="btn btn-primary">Save</button>
                           </div>
                        </div>
                        <div class="tab-pane" id="tvtab_property_2">
                           <div class="form-horizontal">
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Login/Register</label>
                                 <div class="col-md-9">
                                 <Toggle />
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Support Home</label>
                                 <div class="col-md-9">
                                 <Toggle />
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Knowledge Base</label>
                                 <div class="col-md-9">
                                 <Toggle />
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">My Subscriptions</label>
                                 <div class="col-md-9">
                                 <Toggle />
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Ask Question</label>
                                 <div class="col-md-9">
                                 <Toggle />
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Access History</label>
                                 <div class="col-md-9">
                                 <Toggle />
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Update Profile</label>
                                 <div class="col-md-9">
                                 <Toggle />
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Select FAQ Topics</label>
                                 <div class="col-md-9">
                                 <Multiselect
                                             v-model="value"
                                             mode="tags"
                                             placeholder="Select topic"
                                             label="name"
                                             :groups="true"
                                             :searchable="true"
                                             :options="[
                                                {
                                                label: 'Customer Success',
                                                options:  [
                                                   { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                                   { value: '_Feedback', name: '_Feedback',level:'2'},
                                                   { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                                   { value: '_Spam', name: '_Spam',level:'2'},
                                                   { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                                   { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                                   { value: 'Return', name: 'Return',level:'2'},
                                                   { value: 'Return', name: 'Return',level:'2'},
                                                   { value: 'Cancel', name: 'Cancel',level:'2'},
                                                ],
                                                }
                                             ]"
                                             >
                                             <template v-slot:option="{ option }">
                                             <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                             </template>
                                       </Multiselect>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="tab-pane" id="tvtab_property_3">
                           <div class="form-horizontal">
                           <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Menubar Background Color</label>
                                 <div class="col-9 col-md-5">
                                   <input type="text" class="form-control" v-bind:value="color"> 
                                 </div>
                                 <div class="col-3">
                                    <span class="picker-color mright-5" v-bind:style="{ backgroundColor: color}"></span>
                                    <div class="color-picker">
                                       <img src="../assets/images/color-picker-icon.png" v-on:click="colpicker1 = !colpicker1"  />
                                       
                                       <div v-show="colpicker1">
                                          <ColorPicker :color="color" @color-change="updateColor" default-format="hex"  :visible-formats="['hex']" />
                                       </div>
                                    </div>
                                    
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Menubar Item Color</label>
                                 <div class="col-9 col-md-5">
                                 <input type="text" class="form-control" v-bind:value="color1">
                                 </div>
                                 <div class="col-3">
                                    <span class="picker-color mright-5" v-bind:style="{ backgroundColor: color1}"></span>
                                    <div class="color-picker">
                                          <img src="../assets/images/color-picker-icon.png" v-on:click="colpicker2 = !colpicker2"  />
                                          <div v-show="colpicker2">
                                             <ColorPicker :color="color1" @color-change="updateColor1" default-format="hex"  :visible-formats="['hex']" />
                                          </div>
                                       </div>
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Menubar Item Font Size</label>
                                 <div class="col-md-5">
                                 <input type="text" class="form-control">
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Menubar Font Style</label>
                                 <div class="col-md-5">
                                 <input type="text" class="form-control">
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Button Color</label>
                              <div class="col-9 col-md-5">
                                 <input type="text" class="form-control" v-bind:value="color3">
                                 </div>
                                 <div class="col-3">
                                    <span class="picker-color mright-5" v-bind:style="{ backgroundColor: color3}"></span>
                                    <div class="color-picker">
                                          <img src="../assets/images/color-picker-icon.png" v-on:click="colpicker3 = !colpicker3"  />
                                          <div v-show="colpicker3">
                                             <ColorPicker :color="color3" @color-change="updateColor3" default-format="hex"  :visible-formats="['hex']" />
                                          </div>
                                       </div>
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Button Font Color</label>
                                 <div class="col-9 col-md-5">
                                 <input type="text" class="form-control" v-bind:value="color4">
                                 </div>
                                 <div class="col-3">
                                    <span class="picker-color mright-5" v-bind:style="{ backgroundColor: color4}"></span>
                                    <div class="color-picker">
                                          <img src="../assets/images/color-picker-icon.png" v-on:click="colpicker4 = !colpicker4"  />
                                          <div v-show="colpicker4">
                                             <ColorPicker :color="color4" @color-change="updateColor4" default-format="hex"  :visible-formats="['hex']" />
                                          </div>
                                       </div>
                                 </div>
                              </div>
                           </div>
                              <button class="btn btn-primary">Save</button>
                        </div>
                        <div class="tab-pane" id="tvtab_property_4">
                           <div class="form-horizontal">
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Head Element</label>
                                 <div class="col-md-9">
                                 <textarea type="text" class="form-control h-300p"></textarea>
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Component Templates</label>
                                 <div class="col-md-9">
                                 <textarea type="text" class="form-control h-300p"></textarea>
                                 </div>
                              </div>
                               <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Components Javascript</label>
                                 <div class="col-md-9">
                                 <textarea type="text" class="form-control h-300p"></textarea>
                                 </div>
                              </div>
                              <button class="btn btn-primary">Save</button>
                           </div>
                        </div>
                        <div class="tab-pane active" id="tvtab_property_5">
                           <div class="form-horizontal">
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Portal Name</label>
                                 <div class="col-md-7">
                                    <input type="text" class="form-control">
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Portal Description</label>
                                 <div class="col-md-7">
                                    <input type="text" class="form-control">
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label class="col-md-3 control-label text-left">Portal URL</label>
                                 <div class="col-md-7">
                                    <input type="text" class="form-control">
                                 </div>
                              </div>
                              <button class="btn btn-primary">Save</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div>
     </div>
   </div>  -->

   <div class="row">
      <div class="col-md-12">
         <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
               <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" data-bs-target="#tvtab_detail_1" role="tab" aria-controls="formbody" aria-selected="true"><i class="fa fa-gear"></i><span data-bs-toggle="tooltip" data-bs-placement="bottom">Header/Footer</span></a></li>
               <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tvtab_property_2" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-cubes"></i>  <span>Page Components</span></a> </li>
               <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tvtab_property_3" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa  fa-desktop"></i> <span>Theme Colors </span></a> </li>
               <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tvtab_property_4" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-css3"></i> <span>Override Code  </span></a> </li> 
            </ul>
            <div class="tab-content">
               <div class="tab-pane active" id="tvtab_detail_1">
                  <div class="row form-verticle">
                       <p class="col-md-12">
Enter the complete HTML for the header that will appear above
the portal. This usually includes your company logo and website
navigation links. You can use this to make the portal appear
more integrations with your company website.
                              </p>
                              <div class="form-group">
                                 <label class="col-md-12 control-label text-left">Header HTML</label>
                                 <div class="col-md-9">
                                 <textarea class="form-control h-300p"></textarea>
                                 </div>
                              </div>
                              <p  class="col-md-12">
              Enter the complete HTML for the header that will appear below
the portal. This usually includes details that you include within
the footer of your company website. You can use this to make the portal appear
more integrations with your company website.
                              </p>
                     <div class="form-group">
                        <label class="col-md-12 control-label text-left">Footer HTML</label>
                        <div class="col-md-9">
                          <textarea class="form-control h-300p"></textarea>
                        </div>
                     </div>
                    <div class="col-md-12">
                        <button class="btn btn-primary">Save</button>
                     </div>
                  </div>
               </div>
               <div class="tab-pane" id="tvtab_property_2">
                 <p>
Select the feature that you want to appear within your
portal. You must enable the login button to include My
Subscriptions, My Inbox, or My Profile in the portal.
                              </p>
                  <div class="form-horizontal">
                     <div class="form-group">
                        <label class="col-md-3 control-label text-left">Login/Register</label>
                        <div class="col-md-9">
                          <Toggle />
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="col-md-3 control-label text-left">Google Login/Register</label>
                        <div class="col-md-9">
                          <Toggle />
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="col-md-3 control-label text-left">Microsoft Login/Register</label>
                        <div class="col-md-9">
                          <Toggle />
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="col-md-3 control-label text-left">My Subscriptions</label>
                        <div class="col-md-9">
                          <Toggle />
                        </div>
                     </div>
                      <div class="form-group">
                        <label class="col-md-3 control-label text-left">My Inbox</label>
                        <div class="col-md-9">
                          <Toggle />
                        </div>
                     </div>
                      <div class="form-group">
                        <label class="col-md-3 control-label text-left">My Profile</label>
                        <div class="col-md-9">
                          <Toggle />
                        </div>
                     </div>
                      <div class="form-group">
                        <label class="col-md-3 control-label text-left">Knowledge Base</label>
                        <div class="col-md-9">
                          <Toggle />
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="col-md-3 control-label text-left">Asq Question</label>
                        <div class="col-md-9">
                          <Toggle />
                        </div>
                     </div>
                    
                  </div>
               </div>
               <div class="tab-pane" id="tvtab_property_3">
                  <div class="row">
                      
                      <p>
                        Select the color theme for your portal. You can override colors by adding custom CSS to the Override Code Panel.
                     </p>
                     
                     <div class="col-md-2">
                       <div class="row">
                        <div class="theme-pallate">
                          
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-1">
                              <input type="radio" checked class="mright-15" name="theme" value="theme1" v-model="theme" v-on:click="selecttheme('theme1')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                             
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-2">
                              <input type="radio" class="mright-15" name="theme" value="theme2" v-model="theme"  v-on:click="selecttheme('theme2')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                               
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-3">
                               <input type="radio" class="mright-15" name="theme" value="theme3" v-model="theme"  v-on:click="selecttheme('theme3')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                              
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-4">
                              <input type="radio" class="mright-15" name="theme" value="theme4" v-model="theme"  v-on:click="selecttheme('theme4')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-5">
                              <input type="radio" class="mright-15" name="theme" value="theme5" v-model="theme"  v-on:click="selecttheme('theme5')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-6">
                              <input type="radio" class="mright-15" name="theme" value="theme6" v-model="theme"  v-on:click="selecttheme('theme6')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-7">
                              <input type="radio" class="mright-15" name="theme" value="theme7" v-model="theme"  v-on:click="selecttheme('theme7')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-8">
                              <input type="radio" class="mright-15" name="theme" value="theme8" v-model="theme"  v-on:click="selecttheme('theme8')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-9">
                              <input type="radio" class="mright-15" name="theme" value="theme9" v-model="theme"  v-on:click="selecttheme('theme9')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-10">
                              <input type="radio" class="mright-15" name="theme" value="theme10" v-model="theme"  v-on:click="selecttheme('theme10')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-11">
                              <input type="radio" class="mright-15" name="theme" value="theme11" v-model="theme"  v-on:click="selecttheme('theme11')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-12">
                              <input type="radio" class="mright-15" name="theme" value="theme12" v-model="theme"  v-on:click="selecttheme('theme12')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-13">
                              <input type="radio" class="mright-15" name="theme" value="theme13" v-model="theme"  v-on:click="selecttheme('theme13')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-14">
                              <input type="radio" class="mright-15" name="theme" value="theme14" v-model="theme"  v-on:click="selecttheme('theme14')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                           <div class="col-sm-12 col-md-12 color-palette-box tv-theme-15">
                              <input type="radio" class="mright-15" name="theme" value="theme15" v-model="theme"  v-on:click="selecttheme('theme15')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                            <div class="col-sm-12 col-md-12 color-palette-box tv-theme-16">
                              <input type="radio" class="mright-15" name="theme" value="theme16" v-model="theme"  v-on:click="selecttheme('theme16')">
                              <div class="color-palette-set">
                                 <div class="color-palette primary-color"></div>
                                 <div class="color-palette secondary-color"></div>
                              </div>
                           </div>
                        </div>
                       </div>
                     </div>

                     <div class="col-md-10">
                        <div class="preview-box">
                          <a :href="getImgUrl(theme)" target="_blank">
                           <img :src="getImgUrl(theme)">
                          </a>
                        </div>
                     </div>
                  </div>

               </div>
               <div class="tab-pane" id="tvtab_property_4">
                  <div class="row form-verticle">
                     <p class="col-md-12 text-red">*Do not use this feature unless you are experienced with CSS and HTML.</p>
                     <p class="col-md-12">
                     You can override any of the code in your portal by using the panels below. Your changes will override any elements you select, including any theme settings.
                     </p>
                     <div class="form-group">
                        <label class="col-md-12 control-label text-left">Head Elements <span v-tooltip="{text: 'You can add your own code to the HEAD section of the portals page. Be sure to surround your changes with the proper tags, such as <style>, <title>, etc.',theme: {placement: 'bottom', }}"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                        <div class="col-md-9">
                          <textarea type="text" class="form-control h-300p"></textarea>
                        </div>
                     </div>
                      <div class="form-group">
                        <label class="col-md-3 control-label text-left">Component Templates <span v-tooltip="{text: 'You can override the Vue.js code that comprises the customer portal. Click here to view the contents of the underlying source code for the customer portal. Your code entered in this section will update the Vue.js Components.',theme: {placement: 'bottom', }}"><i class="fa fa-question-circle text-info help-icon"></i></span> </label>
                        <div class="col-md-9">
                          <textarea type="text" class="form-control h-300p"></textarea>
                        </div>
                     </div>
                        <div class="form-group">
                        <label class="col-md-3 control-label text-left">Components Javascript <span v-tooltip="{text: 'You can insert your own JavaScript into the customer portal using the section below. Either enclose your JS within script tags or reference a CDN version of the JS.',theme: {placement: 'bottom', }}"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                        <div class="col-md-9">
                           <textarea type="text" class="form-control h-300p"></textarea>
                        </div>
                     </div>
                     <div class="col-md-12">
                        <button class="btn btn-primary">Save</button>
                     </div>
                  </div>
               </div>
               <div class="tab-pane" id="tvtab_property_5">
                  <div class="form-horizontal">
                     <div class="form-group">
                        <label class="col-md-3 control-label text-left">Portal Name</label>
                        <div class="col-md-7">
                           <input type="text" class="form-control">
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="col-md-3 control-label text-left">Portal Description</label>
                        <div class="col-md-7">
                           <input type="text" class="form-control">
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="col-md-3 control-label text-left">Portal URL</label>
                        <div class="col-md-7">
                           <input type="text" class="form-control">
                        </div>
                     </div>
                     <button class="btn btn-primary">Save</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

</template>

<script>
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import CustomerPortalListTV from '../components/CustomerPortalListTV.vue';
import DashbordConfig from '../components/DashbordConfig.vue';
import Toggle from '@vueform/toggle'
import Multiselect from '@vueform/multiselect'
import { ColorPicker } from "vue-accessible-color-picker";
  export default {
  name: 'Customer Portal Treeview',
   components: {
      DetailsandattachmentsTabContent,
      InteractionPropertyTab,
      Multiselect,
      CustomerPortalListTV,
      ColorPicker,
      DashbordConfig,
      Toggle
  },
  mounted(){
          
      },
   data() {
    return {
       color: 'black',
       color1: 'black',
       colpicker1 :false,
       colpicker2 : false ,
       color3: 'black',
       color4: 'black',
       colpicker3 :false,
       colpicker4 : false ,
       showDetailstv :false,
       showAlert:false,
       createNewtv:false,
       theme:'theme1'
    }
  },

  methods: {
    updateColor (eventData) {
      this.color = eventData.cssColor
    },
    updateColor1 (eventData) {
      this.color1 = eventData.cssColor
    },
    updateColor3 (eventData) {
      this.color3 = eventData.cssColor
    },
    updateColor4 (eventData) {
      this.color4 = eventData.cssColor
    },
    selecttheme(themevar){
       this.theme = themevar +'.png';
    },
    getImgUrl(theme) {

       return require('../assets/theme/'+theme+'.png')
   }
    
  },
  mounted() { 
    this.emitter.on("show-message-details", Data => {
      this.showDetailstv = Data.showDetailstv;
      this.createNewtv = false;
    });
  }

}

</script>